module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"globart-academy","defaultLang":"de-at","langs":["en-gb","de-at"],"shortenUrlLangs":true,"pages":[{"type":"Speaker","match":"/:lang?/speaker/:uid","path":"/speaker","component":"/opt/build/repo/src/templates/speaker.js","langs":["en-gb","de-at"]},{"type":"Event","match":"/:lang?/events/:uid","path":"/events","component":"/opt/build/repo/src/templates/event.js","langs":["en-gb","de-at"]}],"sharpKeys":[{},"profilepic"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-cookiehub/gatsby-browser.js'),
      options: {"plugins":[],"cookihubId":"globart-academy","trackingId":"UA-141930893-1","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-141930893-1","head":false,"anonymize":true,"sampleRate":5,"siteSpeedSampleRate":10,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.globart-academy.at"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GLOBART ACADEMY 2021 SINN","short_name":"GLOBART ACADEMY","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"src/images/globart-academy-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2275f8fa78db6101fd5bfaf575c16e04"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
